<template>
  <q-form ref="editForm">
    <c-card title="실시 진행사항" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable&&!disabled&&!completeCheck" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="relatedWork"
            mappingType="PUT"
            label="완료" 
            icon="check"
            @beforeAction="completeRelatedWork"
            @btnCallback="completeRelatedWorkCallback" />
          <c-btn 
            v-if="editable&&!disabled&&!completeCheck" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="relatedWork"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveRelatedWork"
            @btnCallback="saveRelatedWorkCallback"/>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            :editable="editable"
            :disabled="!emergencyCheck||disabled||completeCheck"
            label="완료예정일"
            name="actionScheduleCompleteDate"
            v-model="relatedWork.actionScheduleCompleteDate"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <template v-if="!emergencyCheck">
            <c-text 
              :editable="editable"
              :disabled="true"
              label="담당자" 
              name="actionInfo"
              v-model="actionInfo">
            </c-text>
          </template>
          <template v-else>
            <c-field 
              :required="true" 
              :editable="editable"
              :disabled="disabled||completeCheck"
              :data="relatedWork"
              deptValue="actionDeptCd"
              type="dept_user" 
              label="담당자" 
              name="actionUserId" 
              v-model="relatedWork.actionUserId" />
          </template>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text 
            :editable="editable"
            :disabled="true"
            label="완료일" 
            name="actionCompleteDate"
            v-model="relatedWork.actionCompleteDate">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :required="true"
            :editable="editable"
            :disabled="!emergencyCheck||disabled||completeCheck"
            :data="moc"
            deptValue="checkDeptCd"
            type="dept_user" 
            label="확인자" 
            name="checkUserId" 
            v-model="relatedWork.checkUserId" />
        </div>
        <div class="col-12">
          <c-textarea
            :required="true" 
            :editable="editable"
            :disabled="disabled||completeCheck"
            :rows="3"
            label="확인내용" 
            name="checkContents"
            v-model="relatedWork.checkContents">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-sm"
      title="관련 문서"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="relatedWork.relatedWorkDocuments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled&&!completeCheck"
      selection="multiple"
      rowKey="sopChangeRelatedWorkDocumentId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled&&!completeCheck" label="추가" icon="add" @btnClicked="addDocument" />
          <c-btn v-if="editable&&!disabled&&!completeCheck" label="제외" icon="remove" @btnClicked="removeDocument" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'task-proceeding',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
      }),
    },
    relatedWork: {
      type: Object,
      default: () => ({
        sopChangeRelatedWorkId: '',  // 관련업무 일련번호
        sopMocId: '',  // MOC 일련번호
        mocRelatedTaskCd: '',  // 관련업무 코드
        changeFlag: '',  // 변경/실행 여부
        actionDeptCd: '',  // 실행 담당 부서코드
        actionDeptName: '',  // 실행 담당 부서명
        actionUserId: '',  // 실행 담당자 ID
        actionUserName: '',  // 실행 담당자 명
        actionScheduleCompleteDate: '',  // 실행 완료예정일
        actionCompleteDate: '',  // 실행 완료일
        checkUserId: '',  // 확인자 ID
        checkUserName: '',  // 확인자 명
        checkDate: '',  // 확인일
        checkContents: '',  // 확인내용
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        relatedWorkDocuments: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'documentTitle',
            field: 'documentTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'documentRegUserName',
            field: 'documentRegUserName',
            label: '등록자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'documentRegDt',
            field: 'documentRegDt',
            label: '등록일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        height: '400px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    actionInfo() {
      return this.relatedWork.actionDeptName + ' / ' + this.relatedWork.actionUserName
    },
    completeCheck() {
      return this.relatedWork.completeFlag === 'Y'
    },
    emergencyCheck() {
      return this.moc.mocTypeCd === 'MT00000010'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.moc.relatedWork.singleSave.url
      this.completeUrl = transactionConfig.sop.moc.relatedWork.complete.url
      // code setting
      // list setting
      this.setColumns();
    },
    setColumns() {
      let columns = [];
      switch(this.relatedWork.mocRelatedTaskCd) {
        case 'RT00000001': // 공정안전자료 업데이트
          /**
           * 분류 : 공정안전자료 분류 정보
           *  ex) 동력기계, 장치 및 설비, 공정흐름도, 소화설비 설치도 등등
           * 제목 : 도면의 경우 도면명
           *       시설정보의 경우 시설명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              label: '분류',
              align: 'center',
              style: 'width:20%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:50%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000005': // 위험성평가 결과
          /**
           * 분류 : 위험성평가 종류
           *  ex) HAZOP, K-PSR, KRAS, JSA, Check-list
           * 제목 : 평가명
           * 진행상태 : 위험성평가 진행상태명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              label: '분류',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:40%',
              sortable: false,
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              label: '진행상태',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000010': // 교육 결과
          /**
           * 분류 : 교육과정명
           *  ex) PSM 관련교육, 관리감독자 교육 등등
           * 제목 : 교육명
           * 진행상태 : 교육계획 진행상태명
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              label: '분류',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:40%',
              sortable: false,
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              label: '진행상태',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000015': // 가동전 점검 결과
          /**
           * 제목 : 점검명
           * 진행상태 : 가동전점검 진행상태명
           * 개선진행수 : 
           *  1. 진행중인 건 수 : 개선 완료되지 않은 개선 건 수
           *  2. 완료 된 건 수 : 개선 완료된 건 수  + 즉시조치 건 수
           *  3. 총 건 수 : 개선 건 수
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:40%',
              sortable: false,
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              label: '진행상태',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'impr',
              field: 'impr',
              label: '개선진행',
              align: 'center',
              sortable: false,
              child: [
                {
                  name: 'actionCnt',
                  field: 'actionCnt',
                  label: '진행중인 건 수',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
                {
                  name: 'completeCnt',
                  field: 'completeCnt',
                  label: '완료 된 건 수',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
                {
                  name: 'totalCnt',
                  field: 'totalCnt',
                  label: '총 건 수',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                  style: 'width:10%',
                },
              ]
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000020': // 안전작업 허가서
          /**
           * 분류 : 허가서 종류
           *  ex) 일반, 화기
           * 제목 : 작업개요
           * 진행상태 : 작업허가서 진행상태명
           * 작업 : 작업허가서 작업명
           * 보충작업 : 작업허가서 보충작업(,구분자)
           * 등록자 정보 : 해당 문서가 등록될 때 입력되는 정보
           */
          columns = [
            {
              name: 'classification',
              field: 'classification',
              label: '분류',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:30%',
              sortable: false,
            },
            {
              name: 'documentStepName',
              field: 'documentStepName',
              label: '진행상태',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'sopName',
              field: 'sopName',
              label: '작업',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'supplementWorkTypeNames',
              field: 'supplementWorkTypeNames',
              label: '보충작업',
              align: 'left',
              style: 'width:20%',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:10%',
              sortable: false,
            },
          ];
          break;
        case 'RT00000025': // 기타
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:70%',
              type: 'text',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
        default:
          columns = [
            {
              name: 'documentTitle',
              field: 'documentTitle',
              label: '제목',
              align: 'left',
              style: 'width:70%',
              type: 'text',
              sortable: false,
            },
            {
              name: 'documentRegUserName',
              field: 'documentRegUserName',
              label: '등록자',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
            {
              name: 'documentRegDt',
              field: 'documentRegDt',
              label: '등록일',
              align: 'center',
              style: 'width:15%',
              sortable: false,
            },
          ];
          break;
      }
      this.$set(this.grid, 'columns', columns);
    },
    addDocument() {
      if (this.relatedWork.mocRelatedTaskCd !== 'RT00000025') {
        this.popupOptions.title = '관련 문서';
        this.popupOptions.param = {
          mocRelatedTaskCd: this.relatedWork.mocRelatedTaskCd,
          columns: this.grid.columns
        };
        this.popupOptions.target = () => import(`${'./taskRelationDocu.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else {
        this.relatedWork.relatedWorkDocuments.splice(0, 0, {
          sopChangeRelatedWorkDocumentId: uid(),  // 관련업무 일련번호
          sopChangeRelatedWorkId: this.relatedWork.sopChangeRelatedWorkId,  // 관련업무 일련번호
          documentId: '',  // 문서 일련번호
          documentTitle: '',  // 문서 제목
          documentRegDt: this.$comm.getTodayDateTime(),  // 문서 등록일
          documentRegUserName: this.$store.getters.user.userName,  // 문서 등록자
          documentRegUserId: this.$store.getters.user.userId,  // 문서 등록자 id
        })
      }
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.relatedWork.relatedWorkDocuments) this.relatedWork.relatedWorkDocuments = [];
        this.$_.forEach(data, item => {
          this.relatedWork.relatedWorkDocuments.push({
            sopChangeRelatedWorkDocumentId: uid(),  // 관련업무 일련번호
            sopChangeRelatedWorkId: this.relatedWork.sopChangeRelatedWorkId,  // 관련업무 일련번호
            documentId: item.documentId,  // 문서 일련번호
            documentTitle: item.documentTitle,  // 문서 제목
            documentRegDt: item.documentRegDt,  // 문서 등록일
            documentRegUserName: item.documentRegUserName,  // 문서 등록자

            classification: item.classification,  // 분류
            documentStepName: item.documentStepName,  // 진행상태
            sopName: item.sopName,  // 작업
            supplementWorkTypeNames: item.supplementWorkTypeNames,  // 보충작업
            actionCnt: item.actionCnt,  // 진행중인 건 수
            completeCnt: item.completeCnt,  // 완료 된 건 수
            totalCnt: item.totalCnt,  // 총 건 수
          })
        })
      }
    },
    removeDocument() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.relatedWork.relatedWorkDocuments = this.$_.reject(this.relatedWork.relatedWorkDocuments, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    saveRelatedWork() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.relatedWork.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRelatedWorkCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    completeRelatedWork() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = '완료하시겠습니까?'
          if (!this.relatedWork.relatedWorkDocuments || this.relatedWork.relatedWorkDocuments.length === 0) {
            message = '등록 된 관련문서가 없습니다.\n\r완료하시겠습니까?'
          }
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.relatedWork.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeRelatedWorkCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>
